<template>
  <section class="app-ecommerce-details">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="product === undefined"
    >
      <h4 class="alert-heading">
        Error fetching product data
      </h4>
      <div class="alert-body">
        No item found with this item slug. Check
        <b-link
          class="alert-link"
          :to="{ name: 'marketplace'}"
        >
          Shop
        </b-link>
        for other items.
      </div>
    </b-alert>

    <!-- Content -->
    <b-card
      v-if="product"
      no-body
    >
      <b-card-body>
        <b-row class="my-2">

          <!-- Left: Product Image Container -->
          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-img
                :src="product.image"
                :alt="`Image of ${product.name}`"
                class="product-img"
                fluid
              />
            </div>
          </b-col>

          <!-- Right: Product Details -->
          <b-col
            cols="12"
            md="7"
          >

            <!-- Product Name -->
            <h4>{{ product.product_name }}</h4>

            <!-- Product Brand -->
            <b-card-text class="item-company mb-0">
              <span>by</span>
              <b-link class="company-name">
                {{ product.manufactured }}
              </b-link>
            </b-card-text>

            <!-- Price And Ratings -->
            <div class="ecommerce-details-price d-flex flex-wrap mt-1">
              <h4 class="item-price mr-1">
                ${{ product.price }}
              </h4>
              <ul class="unstyled-list list-inline pl-1 border-left">
                <li
                  v-for="star in 5"
                  :key="star"
                  class="ratings-list-item mr-25"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="18"
                    :class="[{'fill-current': star <= product.rating}, star <= product.rating ? 'text-warning' : 'text-muted']"
                  />
                </li>
              </ul>
            </div>

            <!-- Avability -->
            <b-card-text>Available - 
              <span v-if="product.stock > 0" class="text-success">In stock</span>
              <span v-else class="text-danger">Out of Stock</span></b-card-text>

            <!-- Product Description -->
            <b-card-text>{{ product.description }}</b-card-text>

            <!-- Product Meta [Free shpping, EMI, etc.] -->
            <ul class="product-features list-unstyled">
              <li v-if="product.hasFreeShipping">
                <feather-icon icon="ShoppingCartIcon" />
                <span>Free Shipping</span></li>
              <li>
                <feather-icon icon="DollarSignIcon" />
                <span>EMI options available</span>
              </li>
            </ul>

            <hr>

            <!-- Colors -->
            <div class="product-color-options">
              <h6>Colors</h6>
              <ul class="list-unstyled mb-0">
                <li
                  v-for="color in product.colorOptions"
                  :key="color"
                  class="d-inline-block"
                  :class="{'selected': selectedColor === color}"
                  @click="selectedColor = color"
                >
                  <div
                    class="color-option"
                    :class="`b-${color}`"
                  >
                    <div
                      class="filloption"
                      :class="`bg-${color}`"
                    />
                  </div>
                </li>
              </ul>
            </div>

            <hr>

            <div class="d-flex flex-column flex-sm-row pt-1">
              <b-button
                variant="outline-secondary"
                class="btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0"
                :disabled="$store.state.auth.ActiveUser.email_address === product.created_by"
                @click="
                  product.isInWishlist
                    ? removeWishlist(product.isInWishlist)
                    : addWishlist(product._id)
                "
              >
                <feather-icon
                  icon="HeartIcon"
                  class="mr-50"
                  :class="{'text-danger': product.isInWishlist}"
                />
                <span>Wishlist</span>
              </b-button>

              <b-button
                v-if="product.stock > 0"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :variant="product.isInCart ? 'outline-primary' : 'primary'"
                class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                :disabled="$store.state.auth.ActiveUser.email_address === product.created_by"
                @click="
                  product.isInCart
                    ? $router.push({name: 'marketplace-checkout'})
                    : addToCart(product._id)
                "
              >
                <feather-icon
                  icon="ShoppingCartIcon"
                  class="mr-50"
                />
                <span>{{ product.isInCart ? 'View In Cart' : 'Add to Cart' }}</span>
              </b-button>
              <b-dropdown
                variant="outline-secondary"
                no-caret
                toggle-class="btn-icon"
                class="btn-share"
                right
              >
                <template #button-content>
                  <feather-icon icon="Share2Icon" />
                </template>
                <b-dropdown-item
                  v-for="icon in [ 'FacebookIcon', 'TwitterIcon', 'YoutubeIcon', 'InstagramIcon', ]"
                  :key="icon"
                >
                  <feather-icon :icon="icon" />
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
      </b-card-body>

      <!-- Static Content -->
      <e-commerce-product-details-item-features />

      <!-- Static Content -->
      <!-- Slider: Related Products -->
      <e-commerce-product-details-related-products />
    </b-card>
  </section>
</template>

<script>
import { useRouter } from '@core/utils/utils'
import store from '@/store'
import { ref } from '@vue/composition-api'

import {
  BCard, BCardBody, BRow, BCol, BImg, BCardText, BLink, BButton, BDropdown, BDropdownItem, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ECommerceProductDetailsItemFeatures from './MarketplaceProductDetailsItemFeatures.vue'
import ECommerceProductDetailsRelatedProducts from './MarketplaceProductDetailsRelatedProducts.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,

    // SFC
    ECommerceProductDetailsItemFeatures,
    ECommerceProductDetailsRelatedProducts,
  },
  data() {
    return {
      product: {
        // id: 1,
        // name: 'Apple Watch Series 5',
        // slug: 'apple-watch-series5',
        // image: 'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/1.3b312012.png',
        // rating: 4,
        // price: '339,9',
        // brand: 'Apple',
        // description: 'On Retina display that never sleeps, so it’s easy to see the time and other important information, without raising or tapping the display. New location features, from a built-in compass to current elevation,',
        // isInWishlist: false,
        // isInCart: false,
      },
    }
  },

  created() {
    this.get_product()
  },

  methods: {
    get_product() {
      this.isLoading = true
      const id = this.$route.params.slug

      this.$http
        .get(`/api/product/${id}`)
        .then(response => {
          const { output } = response.data
          // console.log(`🚀 ~ file: MarketplaceProductDetails.vue ~ line 253 ~ get_product ~ output`, output)

          this.product = output

          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
        })
    },

    addWishlist(product_id) {
      const all_data = {
        email: this.$store.state.auth.ActiveUser.email,
        product_id,
      }

      this.$http
        .post(`/api/add-wishlist/`, { data: all_data })
        .then(response => {
          const { success, message } = response.data

          if (success) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Product Notification',
                  icon: 'CheckCircleIcon',
                  text: message,
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )
          } else {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Product Notification',
                  icon: 'AlertCircleIcon',
                  text: 'Failed adding to wishlist',
                  variant: 'error',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }

          this.isLoading = false
          this.get_product()
        })
        .catch(error => {
          // alert('ERROR')
          this.isLoading = false
        })
    },
    removeWishlist(wishlist_id) {
      this.$http
        .delete(`/api/wishlist/${wishlist_id}`)
        .then(response => {
          const { success, message } = response.data

          if (success) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Product Notification',
                  icon: 'CheckCircleIcon',
                  text: message,
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )
          } else {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Product Notification',
                  icon: 'AlertCircleIcon',
                  text: message,
                  variant: 'error',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }

          this.isLoading = false
          this.get_product()
        })
        .catch(error => {
          // alert('ERROR')
          this.isLoading = false
        })
    },

    addToCart(product_id) {
      const form_data = {}
      form_data.quantity = 1

      this.$http
        .post(`/api/add-to-cart/${product_id}`, {
          form_data,
        })
        .then(response => {
          const { success, message } = response.data

          if (success) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Operation Success',
                  icon: 'CheckCircleIcon',
                  text: message,
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )
          } else {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Operation Failed',
                  icon: 'AlertCircleIcon',
                  text: message,
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }

          this.get_product()
        })
        .catch(() => {
          // this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>
